@use '@/styles/utils/mixins.scss' as *;

.humanRiskElementWrapper {
  margin-top: 6.25rem;
  border-radius: 1.25rem;
  border: 0.063rem solid $white;
  width: 100%;
  background-color: $white;
  padding: 3.75rem;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  @include tab() {
    padding: 3.75rem 1.875rem;
  }

  .humanRiskElementContent {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    max-width: 52.5rem;
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .humanRiskElementHeading {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  .humanRiskElementQRSection {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    font-size: 0.875rem;
  }

  :global {
    .customInput {
      max-width: 21.75rem;
    }
  }
}
